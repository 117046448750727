<template>
  <v-carousel
      cycle
      height="600"
      hide-delimiter-background
      show-arrows-on-hover
      hide-delimiters

  >
    <template v-slot:prev="{ on, attrs }">
      <v-btn
          color="success"
          v-bind="attrs"
          v-on="on"
      >Previous slide
      </v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn
          color="info"
          v-bind="attrs"
          v-on="on"
      >Next slide
      </v-btn>
    </template>
    <v-carousel-item
        v-for="(item, i) in imgList"
        :key="i"
        :src="item"
    >
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  props: ['imgList'],
  data() {
    return {
      items: [
        {
          src: "https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/news%2F2020-12-16%2F131672011_1095549667525952_3700386570575839947_n.jpeg?alt=media&token=0158e2d4-2efe-4cdd-97d0-f6cba9f399c5"
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/internationalcooperation%2Fphoto_2019-01-08_23-12-03.jpg?alt=media&token=78d6cce4-bfce-401a-b54e-117da978a174',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/other%2Fphoto_2021-03-10_11-49-39.jpg?alt=media&token=95ad28fa-0646-48bd-ae22-a18cc3877bea',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/other%2Fphoto_2020-06-10_17-15-03.jpg?alt=media&token=86d7b6d8-4a43-4f79-97e4-b09e4f89fba1',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/other%2Fphoto_2020-06-10_17-13-34.jpg?alt=media&token=3e34ad05-f5e5-4021-bfc2-9085e03758b1',
        },
        {
          src: "https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/news%2F2020-12-16%2F131616222_1095549994192586_8507276041109948658_n.jpeg?alt=media&token=228ac5ea-5e0f-4c29-b364-7dc12b07eb0f"
        }
      ],
    }
  },
}
</script>
<template>
  <div>
    <v-container>
      <!--      <h1 style="text-align: left;margin-bottom: 20px">{{$t('home')}}</h1>-->
      <VueSlickCarousel
        :arrows="true"
        :dots="true"
        v-bind="settings"
        v-if="slideImgList.length > 0"
      >
        <div v-for="(d, i) in slideImgList" :key="i">
          <v-col v-if="d">
            <v-img height="320" width="320" :src="d"></v-img>
          </v-col>
        </div>
      </VueSlickCarousel>

      <v-row>
        <v-col cols="12" sm="8" md="8">
          <v-row>
            <v-col
              v-for="(d, i) in newsList"
              :key="i"
              style="text-align: left"
              class="bBottom"
            >
              <span class="ten_tagline">{{ d.createdAt | formatDate }}</span>
              <b
                ><p
                  style="font-family: 'Khmer OS Muol Light'"
                  v-html="getTranslate(d.title)"
                ></p>
              </b>
              <p v-html="getTranslate(d.body)"></p>
              <v-img v-if="d.url" :src="d.url"></v-img>
              <div v-if="d.iframeLive" v-html="d.iframeLive"></div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <side-bar-event></side-bar-event>
          <media></media>
        </v-col>
      </v-row>
    </v-container>
    <Carousel2 :img-list="preface1ImgList" />
    <v-container>
      <v-row>
        <v-col cols="12" sm="8" md="8" style="text-align: left">
          <p class="title">{{ $t("figures") }}</p>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" md="6" sm="6" style="text-align: center">
              {{ $t("lecturer") }}
              <ve-pie
                :data="chartTeacher"
                :settings="chartTeacherSetting"
                height="350px"
              ></ve-pie>
            </v-col>
            <v-col cols="12" md="6" sm="6" style="text-align: center">
              <!--              {{ $t('student') }}-->
              <!--              <ve-pie :data="chartStudent" :settings="chartTeacherSetting"-->
              <!--                      height="350px"></ve-pie>-->
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4" md="4" style="text-align: left">
          <p class="title">{{ $t("thisIsRPITSB") }}</p>
          <v-divider></v-divider>

          <v-col cols="12" sm="12" md="12" v-if="!!videoEmbedCode">
            <iframe
              v-show="!!videoEmbedCode"
              width="100%"
              :height="
                $vuetify.breakpoint.mobile
                  ? windowSize.x / 2
                  : windowSize.x / 2 - (windowSize.x / 2) * 0.6
              "
              :src="videoEmbedCode"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-col>
        </v-col>
      </v-row>
    </v-container>

    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe
          class="gmap_iframe"
          width="100%"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=900&amp;height=700&amp;hl=en&amp;q=វិទ្យាស្ថានពហុបច្ចេកទេសភូមិភាគតេជោសែនបាត់ដំបង &amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
        <a
          href="https://www.fridaynightfunkin.net/friday-night-funkin-mods-fnf-play-online/"
          >FNF Online</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Carousel2 from "@/views/layout/carousel2";
import SideBarEvent from "@/components/sidebarEvent";
import Media from "@/components/sidebarMedia";
import VCharts from "v-charts";
import Vue from "vue";
import MyMixin from "@/mixins/mixin";

Vue.use(VCharts);

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import { Constants } from "@/libs/constant";
import axios from "axios";

export default {
  name: "Home",
  mixins: [MyMixin],
  components: { Media, Carousel2, SideBarEvent, VueSlickCarousel },
  data() {
    this.chartTeacherSetting = {
      labelMap: {
        _id: this.$t("type"),
        number: this.$t("number"),
      },
      label: {
        normal: { show: true, position: "top" },
      },
    };
    return {
      settings: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      videoLink: "",
      videoEmbedCode: "",
      windowSize: {
        x: 0,
        y: 0,
      },
      newsList: [],
      chartTeacher: {
        columns: ["_id", "number"],
        rows: [
          { _id: "Male", number: 55 },
          { _id: "Female", number: 11 },
        ],
      },
      chartStudent: {
        columns: ["_id", "number"],
        rows: [
          { _id: "Male", number: 500 },
          { _id: "Female", number: 490 },
        ],
      },
      intro: {
        en: "",
        km: "",
        cn: "",
      },
      messageFromChairman: {
        en: "",
        km: "",
        cn: "",
      },
      preface1ImgList: [],
      slideImgList: [],
    };
  },
  mounted() {
    this.onResize();
  },
  watch: {
    videoLink(val) {
      let vm = this;
      vm.videoEmbedCode = "";
      if (val && val.split("youtu.be").length === 2) {
        vm.videoEmbedCode = val.replace("youtu.be", "youtube.com/embed");
      } else if (val && val.split("/watch?v=").length > 0) {
        vm.videoEmbedCode =
          "https://www.youtube.com/embed/" +
          val.split("/watch?v=")[1].split("&list=")[0].split("&t=")[0];
      }
    },
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    fetchNewsAndEvent() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getNewsAndEvent;
      return new Promise((resolve, reject) => {
        axios
          .post(
            url,
            { branchId: Constants.branchId },
            { headers: { token: Constants.token } }
          )
          .then(
            (res) => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.newsList = res.data.data.slice(0, 10);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    fetchHome() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getHome;
      vm.slideImgList = [];
      return new Promise((resolve, reject) => {
        axios
          .post(
            url,
            { branchId: Constants.branchId, addToHome: true },
            { headers: { token: Constants.token } }
          )
          .then(
            (res) => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.intro = res.data.data.intro;
                vm.url = res.data.data.url || "";
                vm.messageFromChairman = res.data.data.messageFromChairman;
                vm.preface1ImgList = res.data.data.preface1ImgList || [];

                vm.slideImgList = res.data.data.slideImgList || [];
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
  },
  created() {
    let vm = this;
    //vm.newsList = vm.getNews().slice(0, 5);
    vm.fetchNewsAndEvent();
    vm.videoLink = "https://www.youtube.com/watch?v=hDB-KjY7l-4";
    vm.fetchHome();
  },
};
</script>


<style scoped>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 700px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 700px;
}

.gmap_iframe {
  height: 700px !important;
}
</style>
